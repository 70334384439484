import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [timestamp, setTimestamp] = useState(null); // State to store timestamp
  const [loading, setLoading] = useState(true); // State to handle loading

  useEffect(() => {
    const fetchTimestamp = async () => {
      try {
        const response = await fetch('https://api.dev.protodeal.com/api/ping');
        const data = await response.json();
        setTimestamp(data.timestamp);
      } catch (error) {
        console.error('Error fetching the timestamp:', error);
      } finally {
        setLoading(false);
      }
    };
  

    fetchTimestamp();
  }, []); // Empty dependency array to ensure it only runs once on mount

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hola Javi. Edit <code>src/App.js</code> and push to see the updated site. Testing pipeline 
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>

        {/* Display loading or the fetched timestamp */}
        {loading ? (
          <p>Loading timestamp...</p>
        ) : (
          <p>Current Timestamp: {timestamp}</p>
        )}
      </header>
    </div>
  );
}

export default App;
